<template>
	<div class="wrap__content">
		<div class="container">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['ReportingManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<HeaderBack :title="getReportTitle" :subtitle="$t('reports.name.px-1-m')" />
							<div class="card__content--body">
								<form method="POST" enctype="multipart/form-data" @submit.prevent="onSubmit">
									<AnimalWorldUserName />
									<div class="reporting-line">
										<div class="row">
											<div class="col-xl-4">
												<div class="reporting__title">
													{{ $t('inputs.region') }}
												</div>
											</div>
											<div class="col-xl-5">
												<region
													:name="'region_id'"
													:required="true"
													:key="render_region"
													:errors="$v.form.region_id"
													:modelValue="form.region_id"
													:placeholder="$t('placeholder.choose')"
													:disabled="isNotEditable"
													v-on:update:modelValue="form.region_id = $event"
												>
												</region>
											</div>
										</div>
									</div>
									<div class="reporting-line">
										<div class="row">
											<div class="col-xl-4">
												<div class="reporting__title">
													{{ $t('inputs.pond') }}
												</div>
											</div>
											<div class="col-xl-5">
												<pond
													:required="true"
													:key="render_pond"
													:errors="$v.form.farm_id"
													:modelValue="form.farm_id"
													:name="'farm_id'"
													:placeholder="$t('placeholder.choose')"
													:disabled="isNotEditable"
													v-on:update:modelValue="form.farm_id = $event"
												></pond>
											</div>
										</div>
									</div>

									<kato
										:required="true"
										:hidden="true"
										:errors="$v.form.kato_id"
										:modelValue="form.kato_id"
										:name="'kato_id'"
										:placeholder="$t('placeholder.choose')"
										:disabled="isNotEditable"
										v-on:update:modelValue="form.kato_id = $event"
									>
									</kato>

									<div class="reporting-line">
										<div class="row">
											<div class="col-xl-4">
												<div class="reporting__title">{{ $t('placeholder.select_quater') }}</div>
											</div>
											<div class="col-xl-5">
												<quarter
													:required="true"
													:errors="$v.quarter_value"
													:placeholder="'Выбрать регион'"
													:disabled="isNotEditable || quarter_input_disabled"
													ref="q"
													v-on:update:modelValue="quarter_value = $event"
												>
												</quarter>
											</div>
										</div>

										<div class="input-required" v-if="$v.quarter && !$v.quarter.required">
											{{ $t('system_message.required_message') }}
										</div>
									</div>

									<!-------------
                  <div class="reporting-line">
                    <div class="row">
                      <div class="col-xl-4">
                        <div class="reporting__title">
                          Период от
                        </div>
                      </div>
                      <div class="col-xl-5">
                        <input type="date" v-model="filters.from" class="date__input">
                      </div>
                    </div>
                  </div>
				  ----------------->
									<!------
                  <div class="reporting-line">
                    <div class="row">
                      <div class="col-xl-4">
                        <div class="reporting__title">
                          Период до
                        </div>
                      </div>
                      <div class="col-xl-5">
                        <input type="date" v-model="filters.to" class="date__input">
                      </div>
                    </div>
                  </div>
                ------------------------->
									<hr class="reporting__hr" />

									<div v-if="tradeApp" ref="tradeApp">
										<div
											v-for="(typeCatch, typeCatchInd) in tradeApp"
											:key="typeCatchInd.keyd"
											class="reporting-line"
										>
											<div class="row">
												<div class="col-xl-3">
													<div class="reporting__title">{{ typeCatch.typeCatch }}</div>
												</div>
												<div class="col-xl-9">
													<div class="row total">
														<div class="col-lg-6">
															<TextInput
																:name="'commercial_fishing[0][total_catch_quota]'"
																:title="$t('headers.quatos_to_catch')"
																:type="'number'"
																:required="true"
																:disabled="true"
																:placeholder="$t('headers.quatos_to_catch')"
																:modelValue="getFishesSum(typeCatchInd, 'quota')"
															></TextInput>
														</div>
														<div class="col-lg-6">
															<TextInput
																:name="'commercial_fishing[0][total_actually_caught]'"
																:title="$t('headers.fact_catch')"
																:type="'number'"
																:required="true"
																:disabled="true"
																:placeholder="$t('headers.fact_catch')"
																:modelValue="getFishesSum(typeCatchInd, 'caught')"
															></TextInput>
														</div>
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-xl-3">
													<div class="reporting__title">В том числе по видам рыб</div>
												</div>
												<div class="col-xl-9">
													<template v-if="typeCatch.fishes">
														<div
															v-for="(item, fishIndex) in typeCatch.fishes"
															:key="fishIndex"
															class="items"
														>
															<div class="row mb-2 align-items-center">
																<div class="col-lg-3">
																	<fish
																		:title="'Рыба'"
																		:placeholder="$t('headers.select_fish')"
																		:name="'[' + fishIndex + '][weight]'"
																		:modelValue="item.id"
																		:variant="'def-input'"
																		:disabled="isNotEditable || tradeLoaded"
																		@update:modelValue="
																			updateFishData(typeCatchInd, fishIndex, 'id', $event)
																		"
																	></fish>
																</div>
																<div class="col-lg-3">
																	<TextInput
																		:name="`[catch_quota]`"
																		:title="$t('headers.quatos_to_catch')"
																		:required="true"
																		:placeholder="$t('placeholder.quantity')"
																		:modelValue="item.quota"
																		:disabled="isNotEditable"
																		v-on:blur="
																			updateFishData(typeCatchInd, fishIndex, 'quota', $event)
																		"
																	>
																	</TextInput>
																</div>
																<div class="col-lg-3">
																	<TextInput
																		:name="`[actually_caught]`"
																		:title="$t('headers.fact_catch')"
																		:required="true"
																		:placeholder="$t('placeholder.quantity')"
																		:modelValue="item.caught"
																		:disabled="isNotEditable || tradeLoaded"
																		v-on:blur="
																			updateFishData(typeCatchInd, fishIndex, 'caught', $event)
																		"
																	>
																	</TextInput>
																</div>
																<div v-if="!tradeLoaded" class="col-xl-3">
																	<a
																		v-if="!isNotEditable && typeCatch.fishes.length > 1"
																		@click="removeFish(fishIndex, typeCatchInd)"
																	>
																		<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.024 12.976l4.952-4.952m0 4.952L8.024 8.024M10.5 19.25c4.813 0 8.75-3.938 8.75-8.75 0-4.813-3.938-8.75-8.75-8.75-4.813 0-8.75 3.938-8.75 8.75 0 4.813 3.938 8.75 8.75 8.75z" stroke="#DF4242" stroke-linecap="round" stroke-linejoin="round"/></svg>
																	</a>
																	<button
																		v-if="!isNotEditable"
																		class="input-btn form__line--btn-sign"
																		@click.stop.prevent="addFishes(typeCatchInd)"
																	>
																		<img src="@/assets/img/icon-plus-blue.svg" />
																	</button>
																</div>
															</div>
														</div>
													</template>
												</div>
											</div>
											<hr class="reporting__hr" />
										</div>
									</div>

									<div class="reporting-line">
										<div class="row">
											<div class="col-xl-3">
												<div class="reporting__title">{{ $t('headers.payments_wildlife') }}</div>
											</div>
											<div class="col-xl-9">
												<div class="row">
													<div class="col-lg-6">
														<TextInput
															:title="$t('headers.plan_th_tenge')"
															:type="'number'"
															:name="'payment_plan'"
															:required="true"
															:errors="$v.form.payment_plan"
															:disabled="statusPage == 'show'"
															:placeholder="$t('placeholder.quantity')"
															:modelValue="form.payment_plan"
															:step="0.01"
															v-on:update:modelValue="form.payment_plan = $event"
														></TextInput>
													</div>
													<div class="col-lg-6">
														<TextInput
															:title="$t('headers.fact_payed_th_tenge')"
															:type="'number'"
															:name="'payment_fact'"
															:errors="$v.form.payment_fact"
															:required="true"
															:disabled="statusPage == 'show'"
															:placeholder="$t('placeholder.quantity')"
															:modelValue="form.payment_fact"
															:step="0.01"
															v-on:update:modelValue="form.payment_fact = $event"
														>
														</TextInput>
													</div>
												</div>
											</div>
										</div>
									</div>

									<hr class="reporting__hr" />

									<ReportManageFormButtons
										:edit="!isNotEditable"
										:sign-status="sign_status"
										:report="'1-rh'"
									/>
								</form>

								<ReportManageErrorModal :message="message" :errorMessage="errorMessage" />
								<ReportManageModal @accept="saveElement" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { api } from '@/boot/axios'
import { convertObjectToFormData } from '@/formData'

import { required } from 'vuelidate/lib/validators'
import TextInput from '@/components/components/Inputs/TextInput.vue'
import Region from '@/components/components/Inputs/region'
import quarter from '@/components/components/Inputs/quarter'
import Pond from '@/components/components/Inputs/pond'
import Kato from '@/components/components/Inputs/kato'
import moment from 'moment'
import Fish from '@/components/components/Inputs/fish'

import HeaderBack from '@/components/UI/HeaderBack.vue'
import ReportManageModal from '@/components/reporting-management/ReportManageModal.vue'
import ReportManageFormButtons from '@/components/reporting-management/ReportManageFormButtons.vue'
import ReportManageErrorModal from '@/components/reporting-management/ReportManageErrorModal.vue'
import AnimalWorldUserName from '@/components/reporting-management/AnimalWorldUserName.vue'

export default {
	name: 'ReportPage1',
	components: {
		HeaderBack,
		ReportManageModal,
		ReportManageFormButtons,
		ReportManageErrorModal,
		AnimalWorldUserName,
		Kato,
		Pond,
		Region,
		TextInput,
		quarter,
		Fish,
	},
	props: {
		statusPage: {
			type: String,
			default: 'create',
		},
	},
	validations: {
		quarter_value: {
			required,
		},
		form: {
			region_id: {
				required,
			},
			farm_id: {
				required,
			},
			payment_plan: {
				required,
			},
			payment_fact: {
				required,
			},
		},
	},
	data() {
		return {
			pageType: 'subject',
			pageId: 1,
			form: {
				region_id: null,
				farm_id: null,
				district_id: null,
				kato_id: null,
				payment_fact: null,
				payment_plan: null,
			},
			sign_status: false,
			errorMessage: {
				status: null,
				messages: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},
			application: null,
			userRegions: null,
			tradeLoaded: false,
			tradeApp: [
				{
					typeCatch: 'Любительское (спортивное)',
					typeCatchId: 2,
					fishes: [
						{
							id: new Date().getTime(),
							caught: 0,
							quota: 0,
						},
					],
				},
				{
					typeCatch: 'Промысловое',
					typeCatchId: 1,
					fishes: [
						{
							id: new Date().getTime(),
							caught: 0,
							quota: 0,
						},
					],
				},
			],
			render_pond: 0,
			render_region: 0,
			render_district: 0,
			id: null,
			quarter_input_disabled: false,
			// filters: {
			// 	date_from: null,
			// 	date_to: null,
			// },
			quarter_value: null,
		}
	},
	computed: {
		getReportTitle() {
			if (this.statusPage == 'show') return this.$t('reporting.title')
			else if (this.statusPage == 'edit') return this.$t('headers.report_editing')
			return this.$t('headers.report_adding')
		},
		isNotEditable() {
			return this.statusPage == 'show'
		},
		isFullFilled() {
			return this.statusPage === 'edit' || this.statusPage === 'show'
		},
		getFishesSum() {
			return (typeInd, key) => {
				return (this.tradeApp || [])[typeInd]?.fishes.reduce((summ, elem) => {
					summ += Number(elem[key])
					return summ
				}, 0)
			}
		},
	},
	watch: {
		'$store.state.userObject': {
			immediate: true,
			handler: function () {
				this.apiGetUserRegions()
			},
		},
		'form.region_id': function () {
			this.render_pond++
			this.render_region++
			this.getApplications()
		},
		'form.district_id': function () {
			this.render_district++
		},
		quarter_value() {
			this.getApplications()
		},
		'form.farm_id': function () {
			this.getApplications()
		},
	},
	methods: {
		saveElement() {
			if (this.$configFile.active) return this.sendEcpKey(this.$configFile.xml)
			const websocket = new WebSocket('wss://127.0.0.1:13579/')
			var ready = null
			websocket.onopen = () => {
				ready = true
				handle()
			}
			websocket.onclose = (e) => {
				if (e.wasClean) {
				} else {
					if (this.$i18n.locale == 'kz')
						alert('NCYLayer бағдарламасы іске қосылғандығына көз жеткізіңіз')
					if (this.$i18n.locale == 'ru') alert('Убедитесь, что программа NCALayer запущена')
					if (this.$i18n.locale == 'en') alert('Make sure that the NCALayer program is running')

					//setErrormodal(true);
					setTimeout(() => {
						//setErrormodal(false);
					}, 5000)
				}
			}
			function handle() {
				if (!ready) {
					alert('Убедитесь, что программа NCALayer запущена')
				} else {
					const data = {
						module: 'kz.gov.pki.knca.commonUtils',
						method: 'signXml',
						args: [
							'PKCS12',
							'SIGNATURE',
							'<login><sessionid>caacda70-fd36-45ed-8d94-45a88890f83a</sessionid></login>',
							'',
							'',
						],
					}
					websocket.send(JSON.stringify(data))
				}
			}
			websocket.onmessage = (e) => {
				const data1 = JSON.parse(e.data)
				if (data1.responseObject) {
					this.sendEcpKey(data1.responseObject)
				}
			}
		},
		removeFish(index, typeInd) {
			if (!this.tradeApp[typeInd]) return
			this.tradeApp[typeInd].fishes.splice(index, 1)
		},
		addFishes(typeInd) {
			if (!this.tradeApp[typeInd]) return
			this.tradeApp[typeInd].fishes.push({
				id: new Date().getTime(),
				quantity: 0,
				type: null,
			})
		},
		updateFishData(typeInd, fishInd, key, value) {
			this.tradeApp = this.tradeApp?.map((type, tInd) => {
				if (tInd === typeInd) {
					return {
						typeCatchId: type.typeCatchId,
						typeCatch: type.typeCatch,
						keyd: type.keyd,
						fishes: type.fishes.map((fish, fInd) => {
							if (fInd === fishInd) {
								return {
									...fish,
									[key]: value,
								}
							} else return fish
						}),
					}
				} else return type
			})
		},
		getQuaterInterval() {
			const quarter = moment().month(this.quarter_value)
			const qu = moment(new Date()).utc().quarter()

			if (this.quarter_value / 3 + 1 > qu) {
				quarter.subtract('years', 1)
			}

			const start_quarter = quarter.startOf('month').format('YYYY-MM-D')
			const end_quarter = quarter.add('month', 2).endOf('month').format('YYYY-MM-D')

			return [start_quarter, end_quarter]
		},
		apiGetUserRegions() {
			if (this.$store.state.userObject && this.$store.state.userObject.regions) {
				let array = []
				this.$store.state.userObject.regions.find((element) => {
					array.push(element.region_id)
				})
				this.userRegions = array
			}
		},
		getApplications() {
			if (!this.form.farm_id || !this.form.region_id || typeof this.quarter_value !== 'number')
				return
			const [start_quarter, end_quarter] = this.getQuaterInterval()

			api
				.get('trade/user/from-to', {
					params: {
						surrender_date_start: start_quarter,
						surrender_date_end: end_quarter,
						region_id: this.form.region_id,
						reservoir_id: this.form.farm_id,
					},
				})
				.then((response) => {
					if (response.data?.data?.length) {
						const tradeApp = response.data.data
						if (this.statusPage !== 'create' && this.application) {
							this.application.typeCatches.forEach((typeCatch) => {
								const trade = tradeApp.find(
									(trade) => trade.typeCatchId === typeCatch.type_catch_id,
								)
								if (trade) {
									trade.fishes.forEach((fish) => {
										fish.quota =
											typeCatch.fishes.find((f) => f.fish_id === fish.id)?.quota || fish.quota
										fish.catch =
											typeCatch.fishes.find((f) => f.fish_id === fish.id)?.catch || fish.catch
									})
								}
							})
						}
						this.tradeLoaded = true
						this.tradeApp = tradeApp
					}
				})
				.catch((error) => {
					this.checkError(error)
				})
		},
		// formatDate(date) {
		// 	let str = ''
		// 	const dateArr = date.split('.').reverse()
		// 	str = dateArr[0] + '-' + dateArr[1] + '-' + dateArr[2]
		// 	return str
		// },
		sendEcpKey(ecpString) {
			const formData = new FormData()
			formData.append('xml', ecpString)
			document.querySelector('.preloader').classList.add('active')
			api
				.post(`${this.pageType}/sign/${this.pageId}/${this.$route.params.id}`, formData)
				.then((response) => {
					if (response.status == 200) {
						this.$router.push({
							path: `/${this.$i18n.locale}/account/reporting/${this.pageId}${
								this.pageType === 'subject' ? '' : '-tir'
							}-rh`,
						})
					}
				})
				.catch((error) => {
					this.checkError(error)
				})
				.finally(() => {
					document.querySelector('.preloader').classList.remove('active')
				})
		},
		onSubmit() {
			this.$v.$touch()
			if (!this.$v.$invalid) {
				document.querySelector('.preloader').classList.add('active')
				let formData = convertObjectToFormData(this.form)
				if (this.statusPage == 'edit') formData.append('_method', 'PUT')

				if (this.quarter_value || this.quarter_value === 0) {
					const [start_quarter, end_quarter] = this.getQuaterInterval()

					formData.append('surrender_date_start', start_quarter || '2023-01-01')
					formData.append('surrender_date_end', end_quarter || '2023-01-01')
				}

				this.tradeApp.forEach((trade, tradeInd) => {
					formData.append(`type_catches[${tradeInd}][type_catch_id]`, trade.typeCatchId)
					formData.append(
						`type_catches[${tradeInd}][type_catch_quota]`,
						this.getFishesSum(tradeInd, 'quota'),
					)
					formData.append(
						`type_catches[${tradeInd}][type_catch_caught]`,
						this.getFishesSum(tradeInd, 'caught'),
					)
					trade.fishes.forEach((fish, fishInd) => {
						formData.append(`type_catches[${tradeInd}][fishes][${fishInd}][fish_id]`, fish.id)
						formData.append(`type_catches[${tradeInd}][fishes][${fishInd}][quota]`, fish.quota)
						formData.append(`type_catches[${tradeInd}][fishes][${fishInd}][caught]`, fish.caught)
					})
				})

				// const current_month_number = moment().format('M') - 1

				// const selected_q = this.$refs.q.list_quarter.find((item) => {
				// 	if (item.period.includes(this.quarter_value)) {
				// 		return true
				// 	}
				// })

				// if (!selected_q.period.includes(current_month_number)) {
				// 	document.querySelector('.preloader').classList.remove('active')
				// 	this.message.status = 402
				// 	this.message.text = this.$t('messages.report_overdue')
				// 	this.$modal.show('ReportManageErrorModal')
				// 	return false
				// }

				api
					.post(
						`/${this.pageType}/store/${this.pageId}${
							this.statusPage === 'edit' ? `/${this.$route.params.id}` : ''
						}`,
						formData,
					)
					.then(() => {
						this.$router.push(
							`/ru/account/reporting/${this.pageId}${this.pageType === 'subject' ? '' : '-tir'}-rh`,
						)
					})
					.catch((error) => {
						this.checkError(error)
					})
					.finally(() => {
						document.querySelector('.preloader').classList.remove('active')
					})
			}
		},
		checkError(error) {
			if (error?.response?.status > 500) {
				this.errorMessage.status = 500
				this.errorMessage.text = this.$t('system_message.500')
			} else if (error?.response?.status == 401) {
				this.errorMessage.status = 401
				this.errorMessage.text = error?.response?.data?.message
				this.$router.push('/' + this.$i18n.locale + '/login')
			} else {
				this.errorMessage.status = 422
				this.errorMessage.text = error?.response?.data?.message
			}
			this.$modal.show('ReportManageErrorModal')
		},
		async checkStatusSign() {
			if (!this.isNotEditable) return
			try {
				const response = await api.get(`/${this.pageType}/list/${this.pageId}`)
				this.sign_status =
					response?.data?.data?.find((e) => {
						return e.id == this.$route.params.id
					})?.status === 'Подписан'
			} catch (error) {
				this.checkError(error)
			}
		},
		parseServerData(data) {
			this.application = data
			const datastart = data.surrender_date_start

			if (datastart) {
				const format = moment(datastart, 'YYYY,MM,D')
				const month_number = format.format('M') - 1
				const list_quarters = this.$refs.q.list_quarter
				const quarter_element_selected = list_quarters.find((item) => {
					if (item.period.includes(month_number)) {
						return true
					}
				})

				if (quarter_element_selected) {
					if (Object.values(quarter_element_selected).length > 0) {
						this.quarter_input_disabled = true
						this.$refs.q.value = quarter_element_selected
					} else {
						this.quarter_input_disabled = false
					}
				}
			}

			this.id = data.id
			this.form.region_id = data.region_id
			this.form.farm_id = data.farm_id
			this.form.district_id = data.district_id
			this.form.kato_id = data.kato_id
			this.form.payment_fact = data.payment_fact
			this.form.payment_plan = data.payment_plan

			this.tradeApp = data.typeCatches.map((typeCatch) => {
				return {
					typeCatch: typeCatch.type_catch.title,
					typeCatchId: typeCatch.type_catch.id,
					fishes: typeCatch.fishes.map((fish) => {
						return {
							id: fish?.fish?.id,
							caught: Number(fish.caught),
							quota: Number(fish.quota),
						}
					}),
				}
			})

			// setTimeout(() => {
			// 	document.querySelectorAll('.date__input')[0].value = this.formatDate(
			// 		data.surrender_date_start,
			// 	)
			// 	document.querySelectorAll('.date__input')[1].value = this.formatDate(
			// 		data.surrender_date_end,
			// 	)
			// }, 100)
		},
		async showDetailManagment() {
			if (this.isFullFilled) {
				try {
					const { data } = await api.get(
						`/${this.pageType}/show/${this.pageId}/${this.$route.params.id}`,
					)
					this.parseServerData(data.data)
				} catch (error) {
					this.checkError(error)
				}
			}
		},
	},
	beforeCreate() {
		if (!localStorage.token) this.$router.push('/ru/login')
	},
	async beforeMount() {
		await this.showDetailManagment()
		await this.checkStatusSign()
	},
}
</script>

<style scoped></style>
